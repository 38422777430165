import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import NavItemsComponent from "./navItems"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <footer className="footer mt-auto py-3 bg-secondary-light text-white">
      <div className="container p-4">
        <section className="">

          <div className="row">
            <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
              <h5 className="text-uppercase">Навигация</h5>
              <NavItemsComponent className="text-white"/>
            </div>

            <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
              <h5 className="text-uppercase">Други</h5>

              <ul className="list-unstyled mb-0">
                <li>
                  <a href="#" className="text-white">English</a>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
              <h5 className="text-uppercase">Поверителност</h5>
              <ul className="list-unstyled mb-0">
                <li>
                  <Link to="/privacy#privacy-policy"className="text-white">Декларация за поверителност</Link>
                </li>
                <li>
                  <Link to="/privacy#cookies"className="text-white">Политика за бисквитките</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
              <StaticImage
                src="../images/default.png"
                alt="Odesign"
                width={200}
                height={200}
                className="navbar-brand"
                quality={100}
              />
              <p className="fs-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt" viewBox="0 0 16 16">
                  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
                  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                </svg> София
              </p>
            </div>
          </div>

        </section>

        <section className="mb-4">
          <p>Илюстрациите са предоставени от <a className='text-primary' href="https://icons8.com/">icons8.com</a></p>
        </section>



        <div className="text-center p-3 bg-secondary">
          <p>&#169; Одизайн 2021-{new Date().getFullYear()}. Всички права запазени.</p>
        </div>
      </div>
    </footer>
  )}

export default Footer