import * as React from "react"
import { Link } from "gatsby"

const navItems = [["/", "Начало"], ["/services", "Услуги"], ["/projects", "Проекти"], ["/blog", "Блог"],  ["/contact", "Контакти"]]

const NavItems = ({ className }) => (
  <ul className="navbar-nav me-auto mb-2 mb-lg-0 fs-3">
    {navItems.map((val, i) => {
      return (
        <li key={i} className="nav-item">
          <Link to={val[0]} className={"nav-link " + className} aria-current="page">{val[1]}</Link>
        </li>)
    }
    )}
  </ul>
)

export default NavItems