import React, {useEffect, useRef} from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import CookieConsent from "react-cookie-consent"
import NavItemsComponent from "./navItems"
import Footer from "./footer"
import "@fontsource/comfortaa"
import "@fontsource/montserrat"
import "@fontsource/roboto-slab"
import "./layout.scss"
import { Helmet } from "react-helmet"
import { Collapse } from "bootstrap"

const Layout = ({ children, location }) => {
  const collapseRef = useRef()
  useEffect(() => {
    // @TODO check if this if statement is needed
    if(typeof document !== "undefined") {
      new Collapse(collapseRef.current, {toggle: false})
    }
  }
  )
  return (
    <div className="d-flex flex-column min-vh-100 text-break" id="layout">
      <Helmet>
        <html lang="bg"/>
      </Helmet>
      <header>
        <nav className="navbar navbar-expand-lg navbar-dark bg-secondary">
          <div className="container-fluid">

            <Link to="/" className="navbar-brand">
              <StaticImage
                src="../images/default_cr.png"
                alt="Odesign"
                width={166}
                quality={100}
                className="logo"
              />
            </Link>


            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarColor01" ref={collapseRef}>
              <NavItemsComponent/>
            </div>
          </div>
        </nav>
      </header>
      <main className="flex-grow-1">
        {children}
      </main>
      <Footer/>
      <CookieConsent buttonText='ОК'>
          С използването на сайта Вие приемате, че използваме бисквитки. Вижте нашата <Link to="/privacy" className="text-primary">политика за бисквитките и декларацията за поверителност</Link>.
      </CookieConsent>
    </div>
  )
}

export default Layout